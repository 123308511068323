/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { productPropTypes } from 'helpers/propTypes';
import { itemQuantitySelector } from 'redux/selectors/cart.selector';
import { openProductModal } from 'redux/actions/modal.actions';
import { updateCart } from 'redux/actions/cart.actions';
import { itemFavouriteSelector } from 'redux/selectors/favourite.selector';
import { updateFavourite } from 'redux/actions/favourite.actions';
import { sendSuccessNotification, sendErrorNotification } from 'helpers/notifications';

import Item from 'components/commons/Item';

const ItemContainer = (props) => {
  const {
    cartItem, item, openProductModalAction, updateCartAction, favouriteItem, updateFavouritesAction,
  } = props;

  const quantity = cartItem ? cartItem.quantity : 0;
  const colors = item?.stock_product_descriptions?.filter(
    ({ stock_descriptions }) => stock_descriptions.stock_category_id === 1,
  );
  const talles = item?.stock_product_descriptions?.filter(
    ({ stock_descriptions }) => stock_descriptions.stock_category_id === 2,
  );
  const combinations = colors?.reduce((a, b) => {
    a[b.stock_description_id] = a[b.stock_description_id] || [];
    const combination = item?.stock_product_descriptions
      ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id !== 1)
      ?.find(
        (stock_product_description) => stock_product_description.combination === b.combination,
      );
    if (combination) a[b.stock_description_id].push(combination);
    return a;
  }, Object.create(null));
  const combinationsTalle = talles?.reduce((a, b) => {
    a[b.stock_description_id] = a[b.stock_description_id] || [];
    const combinationTalle = item?.stock_product_descriptions
      ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id !== 2)
      ?.find(
        (stock_product_description) => stock_product_description.combination === b.combination,
      );
    if (combinationTalle) a[b.stock_description_id].push(combinationTalle);
    return a;
  }, Object.create(null));

  const onQuantityChange = (value) => {
    if (value >= 0 && value <= item.stock) {
      updateCartAction(item.id, value, null, {
        id: 0, bool: false, quantity: 0,
      });
    }
  };

  const updateFavouriteItems = (id, action) => {
    updateFavouritesAction(id, action);
    if (!action) {
      sendSuccessNotification('Se agregó a favoritos');
    } else {
      sendErrorNotification('Se eliminó de favoritos');
    }
  };

  return (
    <Item
      item={item}
      onItemClick={() => openProductModalAction(item)}
      onQuantityChange={onQuantityChange}
      quantity={quantity}
      updateFavouriteItems={updateFavouriteItems}
      favouriteItem={favouriteItem}
      colors={colors}
      talles={talles}
      combinations={combinations}
      combinationsTalle={combinationsTalle}
    />
  );
};

const mapStateToProps = (state, props) => ({
  cartItem: itemQuantitySelector(state, props),
  favouriteItem: itemFavouriteSelector(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  openProductModalAction: bindActionCreators(openProductModal, dispatch),
  updateCartAction: bindActionCreators(updateCart, dispatch),
  updateFavouritesAction: bindActionCreators(updateFavourite, dispatch),
});

ItemContainer.defaultProps = {
  cartItem: null,
};

ItemContainer.propTypes = {
  cartItem: PropTypes.shape({
    itemId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
  item: productPropTypes.isRequired,
  openProductModalAction: PropTypes.func.isRequired,
  updateCartAction: PropTypes.func.isRequired,
  favouriteItem: PropTypes.bool.isRequired,
  updateFavouritesAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemContainer);
