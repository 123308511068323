import * as constants from 'redux/constants/category.constants';

const defaultState = {
  items: [],
  itemsHome: [],
  cartelHome: [],
  loading: false,
};

const categoryReducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
  case constants.CATEGORY_GET_CATEGORIES_HOME_REQUESTED:
    return {
      ...state,
      loading: true,
    };
  case constants.CATEGORY_GET_ALL_REQUESTED:
    return {
      ...state,
      loading: true,
    };
  case constants.CATEGORY_GET_CARTEL_HOME_REQUESTED:
    return {
      ...state,
      loading: true,
    };
  case constants.CATEGORY_GET_CATEGORIES_HOME_SUCCEEDED:
    return {
      ...state,
      itemsHome: data.items,
      loading: false,
    };
  case constants.CATEGORY_GET_ALL_SUCCEEDED:
    return {
      ...state,
      items: data.items,
      loading: false,
    };
  case constants.CATEGORY_GET_CARTEL_HOME_SUCCEEDED:
    return {
      ...state,
      cartelHome: data.items,
      loading: false,
    };
  case constants.CATEGORY_GET_CATEGORIES_HOME_FAILED:
  case constants.CATEGORY_GET_ALL_FAILED:
  case constants.CATEGORY_GET_CARTEL_HOME_FAILED:
    return defaultState;
  default:
    return state;
  }
};

export default categoryReducer;
